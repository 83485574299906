





































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateRERPayload, {CreateREREmployeePayload} from "@/dto/request/rer/CreateRERPayload";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import PortalDate from "@/components/common/PortalDate.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import SelectOption from "@/components/common/SelectOption";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import CompanyService from "@/services/CompanyService";
import Workspaces from "@/state/Workspaces";
import EmploymentSearch from "@/components/common/EmploymentSearch.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import PersonalDocumentsAggregateDTO from "@/dto/person/PersonalDocumentsAggregateDTO";
import PersonService from "@/services/PersonService";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {ResidencyStatus} from "@/constants/PersonConstants";
import BankAccountDTO from "@/dto/BankAccountDTO";
import checkLegalAddress from "@/utils/CheckLegalAddress";

const AppModule = namespace("App");

@Component({
  methods: {checkLegalAddress},
  components: {
    SingleFileHolder,
    EmploymentSearch,
    PortalDate,
    PortalSelect,
    SimpleCompanySearch,
    PortalCheckbox,
    PhoneInput,
    PortalInput,
    PortalCollapse,
    CompanySearch
  },
  computed: {
    ResidencyStatus() {
      return ResidencyStatus
    },
    PayPeriodType() {
      return PayPeriodType;
    },
    PayType() {
      return PayType;
    },
    SelectOption() {
      return SelectOption;
    }
  }
})
export default class CreateRER extends Vue {
  @AppModule.Action startLoading!: () => void;
  @AppModule.Action stopLoading!: () => void;

  @Prop()
  onCreated!: () => void;

  @Prop({default: false})
  customerMode!: boolean;

  payload = new CreateRERPayload();

  newEmployee = new CreateREREmployeePayload();
  addRegistered = false;
  selectedEmployee: EmployeeDto | null = null;
  employeeDocuments: PersonalDocumentsAggregateDTO | null = null;
  mainBankAccount: BankAccountDTO | null = null;

  branches: BranchDto[] = [];
  expandedEmployeeIndices: { [key: number]: boolean } = {};
  addMode = true;

  hasOfficer = true;
  hasLegalAddress: boolean | void  = true;

  mounted(): void {
    if (this.customerMode) {
      this.payload.companyId = Workspaces.getCurrent.id;
      this.loadBranches(this.payload.companyId);
    }
  }

  selectCompany(company: CompanyDTO) {
    this.payload.companyId = company.id;
    this.loadBranches(this.payload.companyId).then(
        async (ok) => {
          await this.checkOfficerPresence();
          this.hasLegalAddress = await checkLegalAddress(this.payload.companyId!, this);
        },
        err => processError(err, this)
    );
  }

  loadBranches(id: number) {
    return CompanyService.getBranches(id).then(response => {
      this.branches = response.data;
      this.addMode = true;
    }).then(
        ok => CompanyService.getHeadQuarter(id).then(response => {
          this.branches.push(response.data)
        })
    );
  }

  checkOfficerPresence() {
    return CompanyService.getOfficer(this.payload.companyId!).then(
        ok => {
          this.hasOfficer = !!ok.data;
        },
        err => {
          processError(err, this);
        }
    );
  }

  toggleAddMode() {
    this.addMode = !this.addMode;
  }

  toggleDetails(index: number) {
    this.$set(this.expandedEmployeeIndices, index, !this.expandedEmployeeIndices[index]);
  }

  getBranchTitleAndAddressById(addressId: number) {
    for (const branch of this.branches as BranchDto[]) {
      const addresses = [
        branch.legalAddress,
        branch.mailingAddress,
        branch.actualAddress,
        branch.registrationAgentAddress
      ];

      for (const address of addresses) {
        if (address && address.id === addressId) {
          return {
            title: branch.title,
            address: address.fullAddress
          };
        }
      }
    }
    return {title: 'Not Found', address: 'Not Found'};
  }

  addEmployee() {
    ifValid(this, () => {
      this.payload.employees.push(this.newEmployee);
      this.newEmployee = new CreateREREmployeePayload();
      this.employeeDocuments = null;
      this.selectedEmployee = null;
      this.mainBankAccount = null;
      this.addRegistered = false;
      this.addMode = false;
    });
  }

  create() {
    this.startLoading();
    RERService.create(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    );
  }

  get buildWorkplaces(): Array<SelectOption> {
    let workplaces: Array<SelectOption> = [];
    this.newEmployee.branchesId
        .map(id => this.branches.find(it => it.id == id))
        .map(it => this.assembleAddresses(it!))
        .map(list => list.filter(address => !!address && !!address.fullAddress).map(address => SelectOption.builder().title(address!.fullAddress).value(address!.id).build()))
        .forEach(list => workplaces.push(...list));
    return workplaces;
  }

  assembleAddresses(branch: BranchDto) {
    let res = [];
    if (branch.legalAddress && branch.legalAddress.fullAddress) {
      res.push(branch.legalAddress);
    }
    if (branch.actualAddress && branch.actualAddress.fullAddress) {
      res.push(branch.actualAddress);
    }
    if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
      res.push(branch.mailingAddress);
    }
    if (!AddressDTO.isEmpty(branch.registrationAgentAddress)) {
      res.push(branch.registrationAgentAddress);
    }
    return res;
  }

  buildPayPeriodTypes(): Array<SelectOption> {
    let payPeriodTypes: Array<SelectOption> = [];
    const periodTypes = Object.values(PayPeriodType);
    periodTypes.forEach(period => {
      payPeriodTypes.push(
          SelectOption.builder()
              .title(`${period}`)
              .value(period)
              .build()
      );
    });
    return payPeriodTypes;
  }

  buildPayTypes(): Array<SelectOption> {
    let payTypes: Array<SelectOption> = [];
    const types = Object.values(PayType);
    types.forEach(type => {
      payTypes.push(
          SelectOption.builder()
              .title(`${type}`)
              .value(type)
              .build()
      );
    });
    return payTypes;
  }

  get buildBranches(): Array<SelectOption> {
    return this.branches.map(branch => SelectOption.builder().title(branch.title).value(branch.id).build());
  }

  employeeChanged(employee: EmployeeDto) {
    this.newEmployee.actualizationOnly = true;
    this.newEmployee.employeeId = employee.id;
    this.newEmployee.name = employee.details.name;
    this.newEmployee.email = employee.details.email;
    this.loadPersonalDocuments();
  }

  private loadPersonalDocuments() {
    if (this.selectedEmployee?.details.id) {
      this.startLoading();
      PersonService.getLatestDocumentsAggregate(this.selectedEmployee.details.id).then(
          ok => {
            this.employeeDocuments = ok.data;
            this.newEmployee.residencyStatus = ok.data.residencyStatus;
            this.newEmployee.ssnRequired = !ok.data.ssn;
            this.newEmployee.stateIdRequired = !ok.data.stateID;
            this.newEmployee.eadRequired = !ok.data.ead && ok.data.residencyStatus == ResidencyStatus.NON_CITIZEN;
            this.newEmployee.greenCardRequired = !ok.data.greenCard && ok.data.residencyStatus == ResidencyStatus.PERMANENT_RESIDENT;
            this.newEmployee.citizenPassportRequired = !ok.data.citizenPassport && ok.data.residencyStatus == ResidencyStatus.CITIZEN;
            this.stopLoading();
            this.loadBankAccount();
          },
          err => {
            processError(err, this);
            this.stopLoading();
          }
      )
    }
  }

  private loadBankAccount() {
    if (this.selectedEmployee?.details.id) {
      this.startLoading();
      PersonService.getBankAccounts(this.selectedEmployee?.details.id).then(
          ok => {
            this.mainBankAccount = ok.data.find(acc => acc.isMain) || null;
            this.newEmployee.bankDetailsRequired = !this.mainBankAccount;
            this.stopLoading();
          },
          err => {
            processError(this, err);
          }
      )
    }
  }

  onResidencyStatusSelected(){
    this.employeeDocuments!.residencyStatus = this.newEmployee.residencyStatus;
    this.newEmployee.eadRequired = this.newEmployee.residencyStatus == ResidencyStatus.NON_CITIZEN && !this.employeeDocuments?.ead;
    this.newEmployee.greenCardRequired = this.newEmployee.residencyStatus == ResidencyStatus.PERMANENT_RESIDENT && !this.employeeDocuments?.greenCard;
    this.newEmployee.citizenPassportRequired = this.newEmployee.residencyStatus == ResidencyStatus.CITIZEN && !this.employeeDocuments?.citizenPassport;
  }

}
